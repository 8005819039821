.startegois-gantt-hidden {
  opacity: 1;
  max-height: 0px;
  overflow: hidden;
  transition: max-height 0.5s ease-in-out;
}

.startegois-gantt-visible {
  overflow: scroll;
  opacity: 1;
  transition: max-height 1s ease-in-out;
  max-height: 100%;
}

.startegois-hide-scrollbar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
}

.startegois-hide-scrollbar::-webkit-scrollbar {
  display: none;
}