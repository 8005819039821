@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --clr-black: #020203;
  --clr-white: #ffffff;
  --clr-main-1: #db003d;
  --clr-main-2: #99022c;
  --clr-secondary-1: #707070;
  --clr-secondary-2: #aaaaaa;
  --clr-secondary-3: #d8d8d8;
  --clr-secondary-4: #f6f6f6;
  --clr-error: #eb0000;
  --clr-warn: #ffb100;
  --clr-success: #1bb757;
  --clr-side-a: #cceefc;
  --clr-side-b: #fffccd;

  --clr-warn-darker: #ff7e1b;
  --clr-blue: #1976d2;
  --clr-transparent-background: rgba(0, 0, 0, 0.1);
  --clr-lightgray: #f5f5f5;
  --clr-darkgray: #757575;

  --clr-yellow: #ffe98a;
  --clr-red: #ffaca3;
  --clr-green: #a1e6a3;
  --clr-blue: #91e3e6;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

::-webkit-scrollbar {
  background-color: var(--clr-lightgray);
  height: 8px;
  width: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: var(--clr-darkgray);
  border-radius: 8px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: var(--clr-lightgray);
  border-radius: 8px;
}
